<template class="app">
  <MainPage />
</template>

<script>


import MainPage from './components/MainPage/MainPage.vue'


export default {
  name: 'App',
  components: {
    MainPage
  }
}
</script>

<style src="./App.sass" lang="sass"></style>



